import { StrictMode, useState } from "react";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from 'react-redux'
import { store } from './services/store'
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";

import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';

import 'mantine-datatable/styles.css';
import '@/assets/styles.css';

import './i18n/i18n';
import {
  IconHome,
  IconDashboard,
  IconFileText,
} from '@tabler/icons-react';

import App from "./App";
import { t } from "i18next";

Sentry.init({
  dsn: "https://0a42f0e184cbd58f7a0d03f16457d15b@o320020.ingest.us.sentry.io/4508001668825088",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/uptec\.motivus\.pt\/backoffice\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const myTheme = {
  colors: {
    'bright-yellow':['#faffda','#f8ffad','#f8ff7d','#fbff4b','#fffc1a','#dae600','#9eb300','#688000','#394d00','#131b00']
  },
  primaryColor: 'dark',
  fontFamily: 'Archivo, sans-serif', //'Inter, sans-serif',
  // fontSizes: {
  //   xs: 10,
  //   sm: 12,
  //   md: 14,
  //   lg: 18,
  //   xl: 20,
  // },
  defaultRadius: 'sm',
  // lineHeight:'.75rem',
  components: {
    // Button: {
    //   defaultProps: (theme) => ({
    //     color: "violet.6",
    //   }),
    // },
    TextInput : {
      defaultProps : {
        // size: 'xs'
      }
    },
    Select : {
      defaultProps : {
        placeholder : t('Please select...'),
        // withinPortal: true
      }
    },
    Button : {
      defaultProps : {
        size: 'xs',
        styles: (theme) => ({
          root: {
            textTransform: 'uppercase'
            // minWidth: rem(150)
          }
        })
      }
    },
    Modal : {
      defaultProps : {
        overlayProps : {
          blur:0,
          color: '#ddd',
          opacity: 0.9
        }
      }
    }
  }
};

const spotlightActions = [
  {
    title: 'Home',
    description: 'Get to home page',
    onTrigger: () => console.log('Home'),
    icon: <IconHome size={18} />,
  },
  {
    title: 'Dashboard',
    description: 'Get full information about current system status',
    onTrigger: () => console.log('Dashboard'),
    icon: <IconDashboard size={18} />,
  },
  {
    title: 'Documentation',
    description: 'Visit documentation to lean more about all features',
    onTrigger: () => console.log('Documentation'),
    icon: <IconFileText size={18} />,
  },
]

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={myTheme}>
        {/* <SpotlightProvider actions={spotlightActions} searchPlaceholder="Search..." shortcut="mod + K" nothingFoundMessage="Nothing found..."> */}
          <Notifications position="top-center" />
          <App />
        {/* </SpotlightProvider> */}
      </MantineProvider>
    </ReduxProvider>
  </StrictMode>
);
