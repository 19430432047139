import { Box, FocusTrap, Tabs, TextInput } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { Calendar } from '@mantine/dates';
import { Calculator } from 'react-mac-calculator'
import { IconSearch } from "@tabler/icons-react";
import { useState, useRef } from 'react';
import { Autocomplete, Loader } from '@mantine/core';

import { useClientBillingSaveMutation } from '@/services/api.js';

export const SearchModal = function () {

  const { t } = useTranslation()

  const [criteria, setCriteria] = useState({});
  const [page, setPage]         = useState(1);
  const [sort, setSort]         = useState({columnAccessor:'name',direction:'asc'});
  const [date,setDate]          = useState(Date.now());
  const [value, setValue] = useState('');
  const [data,setData]          = useState([]);

  const [ save, { isLoading } ] = useClientBillingSaveMutation();

  const handleChange = async (val) => {

    setValue(val)

    if (val.trim().length === 0) {
      return true;
    }

    const result = await save(val);

    // setData(['bla']);
    setData(['gmail.com', 'outlook.com', 'yahoo.com'].map((provider) => `${val}@${provider}`));
  };

  return (
    <Box p="md">
      <FocusTrap>

        <Autocomplete
          value={value}
          data={data}
          onChange={handleChange}
          rightSection={isLoading ? <Loader size="1rem" /> : null}
          placeholder="Search clients, contacts, buildings and outher resources"
          leftSection={<IconSearch />}
          data-autofocus
        />

      </FocusTrap>
    </Box>
  )
}
