import { useRouteError } from "react-router-dom";

import { Container, Center, Divider, Text, Code, Paper } from '@mantine/core';
import Logo from '../assets/logoBlack.svg'

import { useTranslation } from 'react-i18next';
import Error from "../components/Error";

export default function ErrorLayout() {
  const error = useRouteError();

  return (
    <Error {...error} />
  )
}
