import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useFocusTrap, useEventListener } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { useLoginMutation,usePasswordRecoverMutation } from '@/services/api.js';
import { useTranslation } from 'react-i18next'
import { AppContext } from '@/services/context'

import { showNotification } from '@mantine/notifications';

import { Center, Divider, Box, Flex, TextInput, PasswordInput, Checkbox, Anchor, Paper, Text, Container, Group, Button, LoadingOverlay } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import Logo from '../assets/logoBlack.svg'

import { animated, useSpring } from '@react-spring/web'

const MyComponent = () => {
  const springs = useSpring({
    from: { x: 0 },
    to: { x: 100 },
  })

  return (
    <animated.div
      style={{
        width: 80,
        height: 80,
        background: '#ff6d6d',
        borderRadius: 8,
        ...springs,
      }}
    />
  )
}

export default function AuthLayout() {

  const { user, setUser } = useContext(AppContext);
  const {t} = useTranslation()

  const handlelogin = async () => {
    const result = await login(form.values);
    if (result?.data?.status=='ok') {
      let nextStep = result.data.payload.next_step
      setUser(result.data.payload)
      navigate(import.meta.env.BASE_URL+'/'+nextStep)
    }
  }

  const handleRecover = async() => {
    const result = await recover(form.values);
    if (result?.data?.status=='ok') {
      setStep(2)
    }
  }

  const [ login, { isLoading:isLoginLoading } ]     = useLoginMutation();
  const [ recover, { isloading:isRecoverLoading } ] = usePasswordRecoverMutation();
  const form                                        = useForm({initialValues:{email:'',password:''}});
  const focusTrapRef                                = useFocusTrap();
  const emailRef                                    = useEventListener('keydown', (ev) => (ev.key=='Enter') ? handlelogin() : false );
  const passwordRef                                 = useEventListener('keydown', (ev) => (ev.key=='Enter') ? handlelogin() : false );
  const navigate                                    = useNavigate()
  const [step, setStep]                             = useState(0)

  return (
    <Container size={450} my={40} ref={focusTrapRef}>
      <div className="loginHolder">

      <Box my={50}>
        <Center><img align="center" src={Logo} /></Center>
        <Center mt={20}>
          <Text fz="md">UPSYS - UPTEC Information System</Text>
        </Center>
      </Box>

      <Paper p={30} mt={30} radius="sm" sx={{background:'none'}}>
        <LoadingOverlay visible={isLoginLoading || isRecoverLoading} overlayProps={{blur:2}} />

        {(step==0) && (
        <div>
          <TextInput ref={emailRef} label={t('auth.Email')} required {...form.getInputProps('email')} />
          <PasswordInput ref={passwordRef} label={t('auth.Password')} required mt="md" {...form.getInputProps('password')} />
          <Divider my="xl" />
          <Flex gap="md" justify="start" align="center">
            <Button onClick={handlelogin}>{t('auth.Sign in')}</Button>
            <Button onClick={()=>setStep(1)} variant="transparent" color="gray" size="sm">{t('auth.Forgot your password?')}</Button>
          </Flex>
        </div>
        )}


        {(step==1) && (
        <div>
          <Text mb="xl">Please type your email and a recovery link will be sent.</Text>
          <TextInput label={t('auth.Email')} required {...form.getInputProps('email')} />
          <Divider my="xl" />
          <Flex gap="md" justify="start" align="center">
            <Button onClick={handleRecover}>{t('auth.Recover password')}</Button>
            <Button onClick={()=>setStep(0)} variant="transparent" color="gray" size="sm">{t('auth.Back to login')}</Button>
          </Flex>
        </div>
        )}

        {(step==2) && (
        <div>
          <Text mb="xl">{t('auth.Please check your email and click the recovery link.')}</Text>
        </div>
        )}

      </Paper>
      </div>

      <Center>
        <Text fz="xs" mt="xl" color="gray">&copy; 2023 UPTEC | development <a href="https://motivus.pt" target="_blank">motivus.pt</a></Text>
      </Center>
    </Container>
  );
}