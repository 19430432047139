import { useState, useContext, forwardRef, useEffect } from 'react';
import { AppContext } from '@/services/context'
import { useInterval } from '@mantine/hooks';

import {
  IconChevronRight,
  IconUser,
  IconKey,
  IconPhoto,
  IconSearch,
  IconLock,
  IconLogout
} from '@tabler/icons-react';

import { CaretUpDown, Wrench } from '@phosphor-icons/react'
import { Group, Avatar, Text, Menu, UnstyledButton, Modal,PasswordInput, Button, Select, Divider, TextInput, Stack } from '@mantine/core';
import { useSetState } from '@mantine/hooks';

import { useLogoutMutation } from '@/services/api.js';
import { useNavigate } from 'react-router-dom';
import { usePingMutation } from '../services/api';

const MyButton = forwardRef(({ image, name, email, icon, ...others }, ref) => {
  return (
    <UnstyledButton
      ref={ref}
      styles={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.md,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black
      })}
      {...others}
    >
      <Group>
        <Avatar src={image} radius="xl" styles={(theme) => ({outline:'1px solid ' + theme.colors.dark[0],outlineOffset:'1px'})} />
        <div style={{ flex: 1 }}>
          <Text size="sm" weight={500}>
            {name}
          </Text>
          <Text color="dimmed" size="xs">
            {email}
          </Text>
        </div>
        {icon || <CaretUpDown size={16} />}
      </Group>
    </UnstyledButton>
  )
})

export default function UserButton() {

  const [state, setState] = useSetState({lockOpen:false,preferencesOpen:false,passwordOpen:false,detailsOpen:false})

  // const interval = useInterval(
  //   () => handleCheckLogin(), 
  //   5000,
  //   { autoInvoke: true }
  // );
  // const [checkLogin] = usePingMutation();
  // const handleCheckLogin = async () => {
  //   if (state.lockOpen==true) {
  //     return true;
  //   }
  //   const result = await checkLogin({});
  //   const status = result?.data?.status;
  //   const action = result?.data?.payload?.action;

  //   if (status!='ok' || action=='reauth') {
  //     setState({lockOpen:true})
  //   } else {
  //     setState({lockOpen:false})
  //   }
  // }  

  // useEffect(() => {
  //   interval.start();
  //   return interval.stop;
  // }, [state.lockOpen]);

  const {user, setUser}   = useContext(AppContext)

  const navigate = useNavigate()
  const [ logout, { isLoading } ] = useLogoutMutation();
  const handleLogout = async () => {
    const result = await logout();
    setUser(null)
    navigate(import.meta.env.BASE_URL+'/')
  }
  // console.log(user)
  return (
    <>
      <Stack>
        {(user?.user_group=='external_admin') && (
          <>
            {user?.entity_parent_name}
            <Divider />
          </>
        )}
        <Group justify="left">
          <Menu shadow="xs">
            <Menu.Target>
              <MyButton
                image={user?.entity_avatar}
                name={user?.entity_name}
                email={user?.user_group_title}
              />
            </Menu.Target>

            <Menu.Dropdown style={{minWidth:'200px'}}>
              <Menu.Label>Account</Menu.Label>
              <Menu.Item icon={<IconUser size={14} onClick={()=>setState({detailsOpen:true})} />}>Personal details</Menu.Item>
              <Menu.Item icon={<Wrench size={14} />} onClick={()=>setState({preferencesOpen:true})}>Preferences</Menu.Item>
              <Menu.Item icon={<IconKey size={14} />} onClick={()=>setState({passwordOpen:true})}>Change password</Menu.Item>
              <Menu.Divider />
              <Menu.Label>Session</Menu.Label>
              <Menu.Item icon={<IconLock size={14} />} onClick={()=>setState({lockOpen:true})}>Lock screen</Menu.Item>
              <Menu.Item color="red" icon={<IconLogout size={14} />} onClick={handleLogout}>Logout</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Stack>

      <Modal
        opened              = {state.lockOpen}
        closeOnEscape       = {false}
        closeOnClickOutside = {false}
        title               = "Screen locked"
        overlayProps        = {{backgroundOpacity:1, color:'#9e9e9e'}}
        withCloseButton     = {false}
        centered
      >
        <TextInput label="Email" placeholder="Email" mt="md" value={user.user_email.toString().substr(0,1) + "*".repeat(Math.floor(Math.random() * (20 - 10 + 1) + 10))} readOnly />
        <PasswordInput label="Password" placeholder="Your password" required mt="md" />
        <Button fullWidth mt="xl" onClick={()=>setState({lockOpen:false})}>Unlock</Button>
      </Modal>

      <Modal
        opened={state.preferencesOpen}
        closeOnEscape={true}
        closeOnClickOutside={false}
        onClose={() => setState({preferencesOpen:false})}
        title="Preferences"
      >
        <div style={{margin:'2rem 1rem'}}>
          <Select value={'pt'} data={[{'label':'Português',value:'pt'},{'label':'English',value:'en'}]} label="Language" withinPortal />
          <Divider mt="xl" mb="md" />
          <Button onClick={()=>setOpened(false)}>Save</Button>
        </div>
      </Modal>

      <Modal
        opened={state.passwordOpen}
        closeOnEscape={true}
        closeOnClickOutside={false}
        onClose={() => setState({passwordOpen:false})}
        title="Preferences"
      >
        <div style={{margin:'2rem 1rem'}}>
          <PasswordInput label="Current passowrd" />
          <Divider mt="xl" mb="md" />
          <PasswordInput label="New passowrd" />
          <PasswordInput label="Please repeat new passowrd" />
          <Button onClick={()=>setOpened(false)}>Save</Button>
        </div>
      </Modal>

      <Modal
        opened={state.detailsOpen}
        closeOnEscape={true}
        closeOnClickOutside={false}
        onClose={() => setState({detailsOpen:false})}
        title="Preferences"
      >
        <div style={{margin:'2rem 1rem'}}>
          <Select value={'pt'} data={[{'label':'Português',value:'pt'},{'label':'English',value:'en'}]} label="Language" withinPortal></Select>
          <Divider mt="xl" mb="md" />
          <Button onClick={()=>setOpened(false)}>Save</Button>
        </div>
      </Modal>
    </>
  );
}