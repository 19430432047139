import { useContext, useRef, useState } from 'react';
import { BackgroundImage, Center, Divider, Box, Flex, TextInput, PasswordInput, Checkbox, Anchor, Paper, Text, Container, Group, Button, LoadingOverlay, PinInput, Stack } from '@mantine/core';
import { useFocusTrap, useEventListener } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useLoginRecoverMutation } from '@/services/api.js';
import { useForm } from '@mantine/form';
import { AppContext } from '@/services/context'

import Logo from '../assets/logoBlack.svg'

export default function RecoverLayout() {

  const { recoverCode } = useParams();
  const [step, setStep] = useState(0)
  const form            = useForm({initialValues:{
    email:'',
    password:'',
    recoverCode:recoverCode
  }});
  const [ save, { isLoading } ] = useLoginRecoverMutation();
  // const { user, setUser } = useContext(AppContext);

  const handlelogin = async () => {
    setVisible(true)
    const result = await save(form.values);
    setVisible(false)

    if (result?.data?.status=='ok') {
      setStep(1)
    }
  }

  const focusTrapRef = useFocusTrap();
  const emailRef = useEventListener('keydown', (ev) => (ev.key=='Enter') ? handlelogin() : false );
  const passwordRef = useEventListener('keydown', (ev) => (ev.key=='Enter') ? handlelogin() : false );
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)

  return (
    <Container size={450} my={40} ref={focusTrapRef}>
      <div className="loginHolder">
        <Box my={50}>
          <Center><img align="center" src={Logo} /></Center>
          <Center mt={20}>
            <Text fz="md">UPSYS - UPTEC Information System</Text>
          </Center>
        </Box>

        <Paper p={30} mt={30} radius="sm" sx={{background:'none'}}>
          <LoadingOverlay visible={visible} overlayProps={{blur:2}} />
          <Divider mb="xl" />
          {(step == 0) && (
          <div>
            <Text mb="lg">Your signup is almost complete.</Text>
            <Text mb="xl">Please type your email and choose a new password:</Text>
            <TextInput ref={emailRef} label="Email" placeholder="you@yourcompany.com" required {...form.getInputProps('email')} />
            <PasswordInput ref={passwordRef} label="Password" placeholder="Your password" required mt="md" {...form.getInputProps('password')} />
            <Divider my="xl" />
            <Flex gap="md" justify="start">
              <Button onClick={handlelogin}>Finish signup</Button>
            </Flex>
          </div>
          )}

          {(step == 1) && (
          <Stack>
            <Text mb="lg" align="center"><strong>Thank you</strong> &mdash; signup complete!</Text>
            <Button onClick={() => location.replace('/')}>Go to login page</Button>
          </Stack>
          )}

        </Paper>
      </div>

      <Center>
        <Text fz="xs" mt="xl" c="gray">&copy; 2023 UPTEC | development <a href="https://motivus.pt" target="_blank">motivus.pt</a></Text>
      </Center>
    </Container>
  );
}