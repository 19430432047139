import { Container, Center, Divider, Text, Code, Paper, Grid, Button, Title, Image, Flex } from '@mantine/core';
import Logo from '../assets/logoBlack.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function Error({error = {}}) {
  const {t} = useTranslation()
  const navigate = useNavigate()

  if (import.meta.env.MODE == 'development') console.error(error);

  return (
    <>
      <Container mt={100}>
        <Center><Title order={3}>UPSYS...</Title></Center>
      </Container>
      <Container>
        <Divider style={{margin:'2rem'}} />

        <Text align="center" fz="xl">{t('error.title')}!</Text>
        <Text align="center" fz="md" color="gray">{t('error.subtitle')}!</Text>
        <Grid justify="center" my="xl" gutter="xl">
          <Grid.Col span={4}>
            <Text align="center">
              <Flex gap="sm">
                <Button onClick={() => navigate(0)} variant="outline">{t('error.tryagain')}</Button>
                <Button onClick={() => navigate(-1)} variant="outline">{t('error.goback')}</Button>
              </Flex>
            </Text>
          </Grid.Col>
          <Grid.Col span={3}>
          <Text align="center">
            <Button component="a" href="mailto:upsys@uptec.pt" variant="gradient">{t('error.contact')}</Button>
          </Text>
          </Grid.Col>
        </Grid>

        {(import.meta.env.MODE == 'development') ? 
        
          <Paper p="xl">
            <Code><pre>{JSON.stringify(error,null,2)}</pre></Code>
            {error.toString()}
          </Paper>

          : ''
        }

        <Center my={100}>
          <Image src={Logo} width={50} opacity={.25} />
          <Divider orientation="vertical" mx="lg" />
          <Text fz="xs">UPSYS - UPTEC INFORMATION SYSTEM</Text>
        </Center>
        

      </Container>
    </>
  )
}
