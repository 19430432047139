import { Tabs } from "@mantine/core"
import { useTranslation } from "react-i18next"

export const NotificationsModal = function () {

  const { t } = useTranslation()

  return (
    <>
      <Tabs defaultValue="unread">
        <Tabs.List position="center">
          <Tabs.Tab value="unread" sx={{ marginLeft: '4rem' }}>{t('Unread')}</Tabs.Tab>
          <Tabs.Tab value="archive">{t('Archive')}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="unread">
        </Tabs.Panel>
        <Tabs.Panel value="archive">
        </Tabs.Panel>
      </Tabs>
    </>
  )
}
