import { 
  Box,
  NavLink,
  Divider,
  Space,
  Text
} from '@mantine/core';

import {
  IconContext,
  Gauge,
  Briefcase,
  Certificate,
  Factory,
  Money,
  Eye,
  Question,
  Users,
  ClockCounterClockwise,
  Gear,
  PhoneCall,
  User,
  UsersThree,
  Table,
  BellRinging,
  ShuffleAngular,
  FileDashed,
  Sparkle,
  Crown,
  Headset
} from '@phosphor-icons/react'

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSetState } from '@mantine/hooks';

export default function AppMenu({type}) {

  const nav      = useNavigate()
  const location = useLocation();
  const iconContext = {
    color: "#666",
    size: 18,
    weight: "regular",
    mirrored: false
  }

  const menuDefaultState = {
    resources: false,
    billing:   false,
    settings:  false
  }
  const [menuState,setMenuState] = useSetState({...menuDefaultState});
  const toggle = (item) => {
    let itemState = !menuState[item]
    let newState = {...menuDefaultState}
    newState[item] = itemState

    setMenuState(newState)
  }

  return (
    <Box>
      <IconContext.Provider value={iconContext}>
        {type == 'external' && (
          <>
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/external/'} label="Dashboard" leftSection={<Gauge />} />
          <Divider my="sm" variant="dotted" />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/external/contractList'} label="Contracts" leftSection={<Certificate />} />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/external/billingList'} label="Billing" leftSection={<Money />} />
          <Divider my="sm" variant="dotted" />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/external/userList'} label="Users" leftSection={<Users />} />
          <Divider my="sm" variant="dotted" />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/external/help'} label="Help" leftSection={<Question />} />
          </>
        )}

        {type == 'service' && (
          <>
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/service/'} label="Dashboard" leftSection={<Gauge />} />
          <Divider my="sm" variant="dotted" />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/service/contactList'} label="Contacts" leftSection={<Users />} />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/service/resourceList'} label="Locations" leftSection={<Factory />} />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/service/readingList'} label="Readings" leftSection={<ClockCounterClockwise />} />
          <Divider my="sm" variant="dotted" />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/service/help'} label="Help" leftSection={<Question />} />
          </>
        )}

        {(type == 'admin' || type == 'operator') && (
          <>
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin'} label="Dashboard" leftSection={<Gauge />} />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/contactList'} label="Contacts" leftSection={<PhoneCall />} />
          <Space h="md" />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/leadList'} label="Leads" leftSection={<Crown />} />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/clientList'} label="Clients" leftSection={<Briefcase />} />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/contractList'} label="Contracts" leftSection={<Certificate />} />
          <Space h="md" />
          
          <NavLink opened={menuState.resources} onClick={() => toggle('resources')} component={Link} to="#" label="Resources" leftSection={<Factory />} childrenOffset={0}>
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/resourceDashboard'} label="Overview" />
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/buildingList'} label="Buildings" />
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/roomList'} label="Rooms" />
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/meterList'} label="Meters" />
            <Space mb="xl" />
          </NavLink>

          <NavLink opened={menuState.billing} onClick={() => toggle('billing')} label="Billing" leftSection={<Money />} childrenOffset={0}>
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/readingList'} label="Readings" />
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/billingList'} label="Billing documents" />
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/pricingList'} label="Pricing" />
          </NavLink>

          {(type=='admin') && (
            <>
              <Space h="md" />
              <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/auditList'} label="Audit" leftSection={<Eye />} />

              <NavLink opened={menuState.settings} onClick={() => toggle('settings')} component={Link} to="#" label="Settings" leftSection={<Gear />} childrenOffset={0}>
                {/* <NavLink component={Link} to="/admin/tables" label="Reference tables" /> */}
                <Space my="sm" />
                <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/workflowList'} label="Workflows" />
                <Space my="sm" />
                <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/messageTypeList'} label="Notification models" />
                <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/messageGroupList'} label="Notification groups" />
                {/* <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/templateList'} label="Templates" /> */}
                <Space my="sm" />
                <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/userList'} label="Users" />
                <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/userGroupList'} label="Profiles" />
              </NavLink>
              <Space h="md" />
              <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/ticketList'} label="Support tickets" leftSection={<Headset />} />

            </>
          )}

          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/help'} label="Help" leftSection={<Question />} />
          </>
        )}
      </IconContext.Provider>
    </Box>
  );
}