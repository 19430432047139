import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import ErrorLayout from './layouts/error';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RecoverLayout from './layouts/recover';

import { AppContext } from './services/context'
import { useLocalStorage, useWindowEvent } from '@mantine/hooks';
import { useState } from 'react';
import { hideNotification, showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';

const adminRoutes = [
  {
    path: import.meta.env.BASE_URL + '/admin',
    element: <AdminLayout type="admin" />,
    // errorElement: <ErrorLayout />,
    children: [
      { path:'', lazy: () => import("./modules/admin/dashboard/Dashboard") },
      { path: "contactList", lazy: () => import("./modules/admin/contacts/ContactList") },

      { path: "leadList", lazy: () => import("./modules/admin/clients/LeadList") },
      { path: "leadView/:id", lazy: () => import("./modules/admin/clients/LeadView") },

      { path: "clientList", lazy: () => import("./modules/admin/clients/ClientList") },
      { path: "clientView/:id", lazy: () => import("./modules/admin/clients/ClientView") },

      { path: "contractList", lazy: () => import("./modules/admin/contracts/ContractList") },
      { path: "contractView/:id", lazy: () => import("./modules/admin/contracts/ContractView") },

      { path: "resourceDashboard", lazy: () => import("./modules/admin/resources/Dashboard") },

      { path: "buildingList", lazy: () => import("./modules/admin/resources/building/BuildingList") },
      { path: "buildingView/:id", lazy: () => import("./modules/admin/resources/building/BuildingView") },

      { path: "roomList", lazy: () => import("./modules/admin/resources/room/RoomList") },
      { path: "roomView/:id", lazy: () => import("./modules/admin/resources/room/RoomView") },

      { path: "meterList", lazy: () => import("./modules/admin/resources/meter/MeterList") },
      { path: "meterView/:id", lazy: () => import("./modules/admin/resources/meter/MeterView") },

      { path: "readingList", lazy: () => import("./modules/admin/billing/ReadingList") },
      { path: "readingView/:id", lazy: () => import("./modules/admin/billing/ReadingView") },

      { path: "billingList", lazy: () => import("./modules/admin/billing/BillingList") },
      { path: "billingView/:id", lazy: () => import("./modules/admin/billing/BillingView") },

      { path: "pricingList", lazy: () => import("./modules/admin/billing/PricingList") },
      { path: "pricingView/:id", lazy: () => import("./modules/admin/billing/PricingView") },

      { path: "auditList", lazy: () => import("./modules/admin/audit/AuditList") },
      { path: "auditView/:id", lazy: () => import("./modules/admin/audit/AuditView") },

      { path: "userList", lazy: () => import("./modules/admin/users/UserList") },
      { path: "userView/:id", lazy: () => import("./modules/admin/users/UserView") },

      { path: "userGroupList", lazy: () => import("./modules/admin/users/UserGroupList") },
      { path: "userGroupView/:id", lazy: () => import("./modules/admin/users/UserGroupView") },

      { path: "messageTypeList", lazy: () => import("./modules/admin/settings/notifications/MessageTypeList") },
      { path: "messageTypeView/:id", lazy: () => import("./modules/admin/settings/notifications/MessageTypeView") },

      { path: "messageGroupList", lazy: () => import("./modules/admin/settings/notifications/MessageGroupList") },
      { path: "messageGroupView/:id", lazy: () => import("./modules/admin/settings/notifications/MessageGroupView") },

      { path: "templateList", lazy: () => import("./modules/admin/settings/templates/TemplateList") },
      { path: "templateView/:id", lazy: () => import("./modules/admin/settings/templates/TemplateView") },

      { path: "workflowList", lazy: () => import("./modules/admin/settings/workflows/WorkflowList") },
      { path: "workflowView/:id", lazy: () => import("./modules/admin/settings/workflows/WorkflowView") },

      { path: "ticketList", lazy: () => import("./modules/admin/tickets/TicketList") },
      { path: "ticketView/:id", lazy: () => import("./modules/admin/tickets/TicketView") },

      { path: "help", lazy: () => import("./modules/admin/help/Faq") },
    ]
  }
]
const operatorRoutes = [
  {
    path: import.meta.env.BASE_URL + '/operator',
    element: <AdminLayout type="operator" />,
    errorElement: <ErrorLayout />,
    children: [
      { path:'', lazy: () => import("./modules/admin/dashboard/Dashboard") },
      { path: "contactList", lazy: () => import("./modules/admin/contacts/ContactList") },

      { path: "leadList", lazy: () => import("./modules/admin/clients/LeadList") },
      { path: "leadView/:id", lazy: () => import("./modules/admin/clients/ClientView") },

      { path: "clientList", lazy: () => import("./modules/admin/clients/ClientList") },
      { path: "clientView/:id", lazy: () => import("./modules/admin/clients/ClientView") },

      { path: "contractList", lazy: () => import("./modules/admin/contracts/ContractList") },
      { path: "contractView/:id", lazy: () => import("./modules/admin/contracts/ContractView") },

      { path: "roomList", lazy: () => import("./modules/admin/resources/room/RoomList") },
      { path: "roomView/:id", lazy: () => import("./modules/admin/resources/room/RoomView") },

      { path: "buildingList", lazy: () => import("./modules/admin/resources/building/BuildingList") },
      { path: "buildingView/:id", lazy: () => import("./modules/admin/resources/building/BuildingView") },

      { path: "readingList", lazy: () => import("./modules/admin/billing/ReadingList") },
      { path: "readingView/:id", lazy: () => import("./modules/admin/billing/ReadingView") },

      { path: "billingList", lazy: () => import("./modules/admin/billing/BillingList") },
      { path: "billingView/:id", lazy: () => import("./modules/admin/billing/BillingView") },

      { path: "pricingList", lazy: () => import("./modules/admin/billing/PricingList") },
      { path: "pricingView/:id", lazy: () => import("./modules/admin/billing/PricingView") },

      // { path: "settings", lazy: () => import("./modules/admin/settings") },
      { path: "help", lazy: () => import("./modules/admin/help/Faq") },
    ]
  }
]
const serviceRoutes = [
  {
    path: import.meta.env.BASE_URL + '/service',
    element: <AdminLayout type="service" />,
    errorElement: <ErrorLayout />,
    children: [
      { path:'', lazy: () => import("./modules/service/dashboard/Dashboard") },
      { path: "contactList", lazy: () => import("./modules/service/contacts/ContactList") },
      // { path: "resourceList", lazy: () => import("./modules/service/resources/ResourceList") },
      { path: "readingList", lazy: () => import("./modules/service/readings/ReadingList") },
      { path: "help", lazy: () => import("./modules/admin/help/Faq") },
    ]
  }
]
const externalRoutes = [
  {
    path: import.meta.env.BASE_URL + '/external',
    element: <AdminLayout type="external" />,
    errorElement: <ErrorLayout />,
    children: [
      { path:'', lazy: () => import("./modules/external/dashboard/Dashboard") },
      { path: "contractList", lazy: () => import("./modules/external/contracts/ContractList") },
      { path: "contractView/:id", lazy: () => import("./modules/external/contracts/ContractView") },
      { path: "userList", lazy: () => import("./modules/external/users/UserList") },
      { path: "billingList", lazy: () => import("./modules/external/billing/BillingList") },
      { path: "help", lazy: () => import("./modules/admin/help/Faq") },
    ]
  }
]
const router = createBrowserRouter([
  {
    path: import.meta.env.BASE_URL + '/*',
    element: <ErrorLayout />,
  },
  {
    path: import.meta.env.BASE_URL,
    element: <AuthLayout />,
    errorElement: <ErrorLayout />
  },
  {
    path: import.meta.env.BASE_URL + '/recover/:recoverCode',
    element: <RecoverLayout />,
    errorElement: <ErrorLayout />
  },
  ...adminRoutes,
  ...operatorRoutes,
  ...serviceRoutes,
  ...externalRoutes,
]);

export default function App() {

  const [user,setUser] = useLocalStorage({
    key: 'user',
    defaultValue: null,
  })

  const setNetworkStatus = (status) => {
    if (status == 'online') {
      hideNotification('networkStatus')
      showNotification({
        withCloseButton: true,
        autoClose      : 3000,
        color          : "green",
        icon           : <IconCheck size={16} />,
        title          : "Application online",
        message        : 
          "And, we're back!!"
      });
    }
    if (status == 'offline') {
      showNotification({
        id             : "networkStatus",
        withCloseButton: false,
        autoClose      : false,
        color          : "red",
        icon           : <IconX size={16} />,
        title          : "Application offline",
        message        : 
          "Your computer has lost access to the network - please wait a few moments or check your connection."
      });
    }
  }

  useWindowEvent('online',() => setNetworkStatus('online'))
  useWindowEvent('offline',() => setNetworkStatus('offline'))

  return (
    <AppContext.Provider value={{user,setUser}}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
}