import { Tabs } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { Calendar } from '@mantine/dates';
import { Calculator } from 'react-mac-calculator'

export const UtilsModal = function () {

  const { t } = useTranslation()

  return (
    <>
      <Tabs defaultValue="calendar">
        <Tabs.List justify="center">
          <Tabs.Tab value="calendar" sx={{ marginLeft: '4rem' }}>{t('Calendar')}</Tabs.Tab>
          {/* <Tabs.Tab value="weather">{t('Weather')}</Tabs.Tab> */}
          <Tabs.Tab value="calculator">{t('Calculator')}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="calendar">
          <div style={{margin:'0 auto',width:340}}>
            <Calendar size="lg" />
          </div>
        </Tabs.Panel>
        <Tabs.Panel value="weather">
          
        </Tabs.Panel>
        <Tabs.Panel value="calculator">
          <div style={{margin:'0 auto',width:320}}>
            <Calculator style={{boxShadow:'none'}} />
          </div>
        </Tabs.Panel>
      </Tabs>
    </>
  )
}
